export const heroSlideshowImgSizes = {
  tablet: {
    landscape: "90vw",
    portrait: "40vw",
  },
  mobileLandscape: {
    landscape: "100vw",
    portrait: "70vw",
  },
  base: {
    landscape: "120vw",
    portrait: "70vw",
  },
};
