import React from "react";
import dynamic from "next/dynamic";
import styled from "styled-components";
import PageMargins from "../styled/PageMargins";
import { FlexibleContent } from "./FlexibleContent";
import { RowIntro } from "./RowIntro";
import { StyledFlexibleRow } from "./StyledFlexibleRow";
import { Heading } from "../ui/Heading";
import {
  HeaderTwoCol,
  HeaderTwoColCol,
} from "~/components/styled/HeaderTwoCol";
import Link from "next/link";
import { HeroSlideshowContainer } from "../styled/HeroSlideshowContainer";
import { ApiImage } from "../ui/ApiImage";
import { FlexibleHeading } from "../styled/FlexibleHeading";
import safeHtml from "~/utils/sanitize";
import { themeImgSizes } from "~/theme";
import { heroSlideshowImgSizes } from "~/theme/imgSizes";
import { safeAnchorId } from "~/utils/safeAnchorId";
import { LoadingIcon } from "../ui/LoadingIcon";

const WhatsOnListing = dynamic(
  () => import("~/components/content/WhatsOnListing")
);

const HeroSlideShow = dynamic(
  () => import("~/components/content/HeroSlideShow")
);

const VerticalSlider = dynamic(() => import("~/components/ui/VerticalSlider"));
const VerticalSliderItemWithTeaser = dynamic(
  () => import("~/components/content/VerticalSliderItemWithTeaser")
);
const ListItemVideoOrAudio = dynamic(
  () => import("~/components/content/ListItemVideoOrAudio")
);

const SpecialPressRelease = dynamic(() => import("./SpecialPressRelease"));
const SpecialDonationForm = dynamic(() => import("./SpecialDonationForm"), {
  loading: () => <LoadingIcon isLoading={true} />,
});

const arrMergeAlternating = (left: any, right: any) => {
  const longer = left?.length >= right.lengt ? left : right;
  const shorter = left?.length >= right.lengt ? right : left;

  const out = [];

  for (let i = 0; i < longer.length; i++) {
    out.push(longer[i]);
    if (i < shorter.length) out.push(shorter[i]);
  }
  return out;
};

const StyledFlexibleRowContainer = styled.div<{ spaceBottom?: number }>`
  ${(props) =>
    props.theme.apply("default", (breakpoint: string) => {
      return `
        ${
          typeof props.spaceBottom !== "undefined"
            ? props.spaceBottom
              ? `margin-bottom: ${props.theme.spacePx(
                  breakpoint,
                  props.spaceBottom
                )};`
              : ""
            : `margin-bottom: ${(
                props.theme.space(breakpoint, 4) -
                props.theme.space(breakpoint, 5)
              ).toFixed(2)}px`
        };
      `;
    })}
`;

const PlayfulLayout = styled.div`
  width: 100%;
`;

const PlayfulContainer = styled(StyledFlexibleRowContainer)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  ${({ theme }) => theme.breakpoints.tablet} {
    justify-content: space-evenly;
  }
`;

const PlayfulPadding = styled.div`
  ${({ theme }) => theme.breakpoints.tablet} {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-content: center;

    &:nth-child(1) {
      padding-top: 0;
      margin-top: 0;

      & > * {
        margin-top: 0;
      }
    }

    &:nth-child(2n + 2) {
      justify-content: flex-end;
    }

    & > * {
      width: calc(50% - 20px);
      margin-top: min(-100px, -10vh);
      overflow: hidden;
      min-height: 300px;
      margin-bottom: 0;
    }

    .pad:nth-child(1) .content {
      margin-top: 0;
    }
  }
`;

const TwoColumn = styled.div<{
  vAlignContent: "top" | "center" | "sticky-l" | "sticky-r";
  layout: "narrow-right" | "narrow-left" | "50-50";
  hideNarrowOnSmallerDevices: boolean;
}>`
  display: block;

  ${({ hideNarrowOnSmallerDevices, layout }) =>
    hideNarrowOnSmallerDevices
      ? `
    ${layout === "narrow-left" ? `> div:first-child {display:none;}` : ""}
    ${layout === "narrow-right" ? `> div:last-child {display:none;}` : ""}
  `
      : ``}

  ${({ theme }) => theme.breakpoints.tablet} {
    ${({ hideNarrowOnSmallerDevices, layout }) =>
      !hideNarrowOnSmallerDevices || layout === "50-50"
        ? `
          display: grid;
          align-items: start;
          grid-template-columns: 1fr 1fr;
          `
        : ``}

    ${({ vAlignContent }) =>
      vAlignContent === "sticky-l"
        ? ` 
          & > :first-child {
            position: sticky;
          }`
        : ``}

    ${({ vAlignContent }) =>
      vAlignContent === "sticky-r"
        ? ` 
          & > :last-child {
            position: sticky;
          }`
        : ``}
  }

  ${({ theme }) => theme.breakpoints.tabletLandscape} {
    display: grid;
    align-items: start;
    
    > div:first-child,
    > div:last-child {
      display: block;
    }

    grid-template-columns: ${({ layout }) => {
      switch (layout) {
        case "50-50":
          return "1fr 1fr";

        case "narrow-left":
          return "1fr 2fr";
      }
      return "2fr 1fr";
    }};
  }

  ${(props) =>
    props.theme.apply(
      ["tablet", "tabletLandscape", "desktop", "screen"],
      (breakpoint: string) => {
        return `
        column-gap: ${props.theme.spacePx(
          breakpoint,
          breakpoint !== "tablet" ? 6 : 7
        )};
        & > :first-child,
        & > :last-child {
          top:  ${(props.theme.space(breakpoint, 6) * 2).toFixed(2)}px;
          }
      `;
      }
    )}
`;

export const FlexibleRow = ({
  content,
  field = "content",
  fontStyle = "default",
  spaceBottom,
}: {
  content: any;
  field: string;
  fontStyle?: string;
  spaceBottom?: number;
}) => {
  let renderedIndex = -1;

  const parsedContent: any[] = Array.isArray(content)
    ? content.map((block: any, index: number) => {
        if (block.acf_fc_layout === "intro") {
          renderedIndex++;
          return (
            <RowIntro
              {...{ block, field, index: renderedIndex }}
              key={`flx-${field}-${renderedIndex}`}
              fontStyle={fontStyle}
              shortBottomMargin={content?.length === 1}
            />
          );
        }

        if (
          block.acf_fc_layout === "column_1" &&
          block?.column_content_content?.length
        ) {
          renderedIndex++;
          return (
            <PageMargins
              key={`flx-${field}-${renderedIndex}`}
              spaceTop={renderedIndex === 0 ? 7 : 0}
            >
              <FlexibleContent
                columnWidth="full"
                field={`content-${field}-${renderedIndex}-content`}
                content={block.column_content_content}
                fontStyle={fontStyle}
              />
            </PageMargins>
          );
        }

        if (
          block.acf_fc_layout === "column_2" &&
          (block?.column_left?.content?.length ||
            block?.column_right?.content?.length)
        ) {
          renderedIndex++;

          const rowLayout =
            block?.align_narrow_column === "50-50"
              ? "50-50"
              : block?.align_narrow_column === "left"
              ? "narrow-left"
              : "narrow-right";

          const leftLayout =
            block?.align_narrow_column === "50-50"
              ? "50"
              : block?.align_narrow_column === "left"
              ? "short"
              : "wide";

          const rightLayout =
            block?.align_narrow_column === "50-50"
              ? "50"
              : block?.align_narrow_column === "left"
              ? "wide"
              : "short";

          return (
            <PageMargins
              key={`flx-${field}-${renderedIndex}`}
              spaceTop={renderedIndex === 0 ? 7 : 0}
            >
              {block?.align_content === "center" ? (
                <PlayfulLayout>
                  <FlexibleContent
                    WrapWithContainer={PlayfulContainer}
                    WrapWith={PlayfulPadding}
                    columnWidth="50"
                    field={`content-${field}-${renderedIndex}-content-left`}
                    fontStyle={fontStyle}
                    content={arrMergeAlternating(
                      Array.isArray(block?.column_right?.content)
                        ? block?.column_right?.content
                        : [],
                      Array.isArray(block?.column_left?.content)
                        ? block?.column_left?.content
                        : []
                    )}
                  />
                </PlayfulLayout>
              ) : (
                <TwoColumn
                  vAlignContent={block?.align_content ?? "top"}
                  layout={rowLayout}
                  hideNarrowOnSmallerDevices={block?.hideNarrowColumn}
                >
                  <FlexibleContent
                    columnWidth={leftLayout}
                    field={`content-${field}-${renderedIndex}-content-left`}
                    content={block?.column_left?.content}
                    fontStyle={fontStyle}
                  />
                  <FlexibleContent
                    columnWidth={rightLayout}
                    field={`content-${field}-${renderedIndex}-content-right`}
                    content={block?.column_right?.content}
                    fontStyle={fontStyle}
                  />
                </TwoColumn>
              )}
            </PageMargins>
          );
        }

        if (
          block.acf_fc_layout === "special_donate_form" &&
          block?.amounts?.length
        ) {
          renderedIndex++;
          return (
            <PageMargins
              key={`flx-${field}-${renderedIndex}`}
              spaceTop={renderedIndex === 0 ? 7 : 0}
              spaceMinHeight={2}
              style={{
                position: "relative",
              }}
            >
              <SpecialDonationForm blockIndex={renderedIndex} block={block} />
            </PageMargins>
          );
        }

        if (
          block.acf_fc_layout === "special_vertical_slider" &&
          block?.items?.length > 0
        ) {
          renderedIndex++;
          let moreUrl: string | undefined;
          if (block.show_view_all_link) {
            switch (block.type) {
              case "news":
                moreUrl = `/news${
                  block.category?.length
                    ? `/filter/?categoryId=${block.category.join(",")}`
                    : ``
                }`;
                break;

              case "exhibitions":
                let endpoint = "filter";

                const query = [];
                if (block?.post_type?.length > 1) {
                  query.push(`type=${block.post_type.join(",")}`);
                } else if (block?.post_type?.length === 1) {
                  endpoint = `${block?.post_type[0]}s`;
                }

                if (block?.programme_category?.length > 0)
                  query.push(
                    `programmeCategoryId=${block.programme_category.join(",")}`
                  );

                moreUrl = `/whats-on/${endpoint}/${
                  query?.length > 0 ? `/?${query.join("&")}` : ``
                }`;
                break;

              case "media":
                moreUrl = `/media${
                  block.media_category?.length > 0
                    ? `/filter/?mediaCategory=${block.media_category.join(",")}`
                    : ``
                }`;
                break;
            }
          }
          return (
            <VerticalSlider
              key={`flx-${field}-${renderedIndex}`}
              fontStyle={fontStyle}
              spaceTop={renderedIndex === 0 ? 7 : 0}
              spaceHeight={
                block?.fixed_height && !["media", "pages"].includes(block?.type)
                  ? {
                      base: [1, 2, 8],
                      mobile: [1, 2, 8],
                      tablet: [2, 3, 8],
                      desktop: [2, 3, 8],
                      screen: [2, 3, 8],
                    }
                  : {
                      base: "auto",
                      mobile: "auto",
                      tablet: "auto",
                      desktop: "auto",
                      screen: "auto",
                    }
              }
              header={block.heading}
              hasNav
              moreLinkLabel={block.show_view_all_link ? "View all" : undefined}
              moreLinkUrl={moreUrl}
            >
              {block?.items?.map((item: any, itemIndex: number) => {
                if (item.type === "video" || item.type === "audio_file") {
                  return (
                    <ListItemVideoOrAudio
                      item={item}
                      key={`flx-${field}-${renderedIndex}}-${itemIndex}`}
                      inVerticalSlider
                    />
                  );
                }

                return (
                  <VerticalSliderItemWithTeaser
                    item={item}
                    key={`flx-${field}-${renderedIndex}}-${itemIndex}`}
                  />
                );
              })}
            </VerticalSlider>
          );
        }

        if (
          (block.acf_fc_layout === "anchor" ||
            block.acf_fc_layout === "subpage") &&
          block?.name
        ) {
          renderedIndex++;
          return (
            <div
              key={`flx-${field}-${renderedIndex}`}
              id={safeAnchorId(block?.name)}
            />
          );
        }

        if (
          block.acf_fc_layout === "special_press" &&
          block?.pressReleases?.length
        ) {
          renderedIndex++;
          return (
            <PageMargins
              key={`flx-${field}-${renderedIndex}`}
              spaceTop={renderedIndex === 0 ? 7 : 0}
            >
              <StyledFlexibleRow>
                <SpecialPressRelease data={block?.pressReleases} />
              </StyledFlexibleRow>
            </PageMargins>
          );
        }

        if (
          block.acf_fc_layout === "special_whats_on_listing" &&
          block?.items?.length
        ) {
          renderedIndex++;
          const query = [];
          query.push("type", block.post_type);

          if (block?.programme_category?.length)
            query.push("programmeCategory", block.programme_category.join(","));

          let params = "";
          if (query.length > 1) params = `/?${query.join("&")}`;

          return (
            <WhatsOnListing
              key={`flx-${field}-${renderedIndex}`}
              keyPrefix="past"
              items={block?.items}
              heading={
                <PageMargins spaceTop={renderedIndex === 0 ? 7 : 0}>
                  <HeaderTwoCol>
                    <HeaderTwoColCol gapSize={fontStyle === "bold" ? 8 : 9}>
                      <Heading
                        heading={fontStyle === "bold" ? "h2" : "h3"}
                        asTag
                        space={8}
                        inline
                        html={
                          block?.heading ??
                          (block.post_type === "event"
                            ? "Events"
                            : "Exhibitions")
                        }
                      />
                      {!block?.has_3D && (
                        <Link href={`/whats-on/filter${params}`} passHref>
                          <a>
                            <Heading
                              heading={fontStyle === "bold" ? "h3" : "h4"}
                              space={9}
                              inline
                              html="VIEW ALL"
                            />
                          </a>
                        </Link>
                      )}
                    </HeaderTwoColCol>
                  </HeaderTwoCol>
                </PageMargins>
              }
            />
          );
        }

        if (
          block.acf_fc_layout === "special_gallery" &&
          block?.images?.length > 1
        ) {
          renderedIndex++;
          return (
            <div key={`flx-${field}-${renderedIndex}`}>
              {block?.heading && (
                <PageMargins spaceTop={renderedIndex === 0 ? 7 : 0}>
                  <FlexibleHeading
                    heading={fontStyle === "bold" ? "h2" : "h3"}
                    dangerouslySetInnerHTML={{
                      __html: safeHtml(block.heading),
                    }}
                  />
                </PageMargins>
              )}

              {!block?.heading && renderedIndex === 0 && (
                <PageMargins spaceTop={7} />
              )}

              <HeroSlideshowContainer hasBottomMargin>
                <HeroSlideShow
                  slides={block?.images.map((img: any, itemIndex: number) => (
                    <ApiImage
                      key={`slideshowimg-${itemIndex}`}
                      aspectRatioDirection="height"
                      alt={img?.alt}
                      sizes={img?.sizes}
                      imgSizes={themeImgSizes(heroSlideshowImgSizes)}
                    />
                  ))}
                />
              </HeroSlideshowContainer>
            </div>
          );
        }

        return null;
      })
    : [];

  if (parsedContent.length === 0) return <></>;

  return (
    <StyledFlexibleRowContainer spaceBottom={spaceBottom}>
      {parsedContent}
    </StyledFlexibleRowContainer>
  );
};
