import styled from "styled-components";
import { SvgBackground } from "./SvgBackground";

export const Container = styled.span<{
  align: "left" | "right" | "up" | "down";
  size: number;
  heading: string;
}>`
  display: flex;
  align-items: center;

  flex-direction: ${({ align }) => (align === "left" ? "row-reverse" : "row")};
  

  ${(props) =>
    props.theme.apply("default", (breakpoint: string) => {
      return `
        ${props.theme.textStyle(breakpoint, `${props.heading}Heading`)};
        column-gap: ${["base","mobile","tablet"].includes(breakpoint) ? '5px' : props.theme.spacePx(breakpoint, 10)};

      `;
    })}

  @media (any-pointer: fine) { 
    &:hover {
      color: #000;
      opacity: 0.75;
    }
  }
`;

const Text = styled.span`
  grid-area: text;
  display: inline-block;
`;

const Arrow = styled.span<{
  size: number;
  sizeCorrector: number;
  tuckUp: number;
  align: "left" | "right" | "up" | "down";
  filter?: string;
}>`
  grid-area: arrow;
  display: inline-block;
  transform: ${({ align, tuckUp }) =>
    align === "left" || align === "up"
      ? `rotate(180deg) translateY(${tuckUp}px)`
      : `translateY(${tuckUp * -1}px)`};

  ${({filter}) => filter ? `filter: ${filter};`:''}
  
  ${(props) =>
    props.theme.apply("default", (breakpoint: string) => {
      return `
        width: ${(
          props.sizeCorrector * 2 +
          props.theme.space(breakpoint, props.size)
        ).toFixed(2)}px;
        height: ${(
          props.sizeCorrector * 2 +
          props.theme.space(breakpoint, props.size)
        ).toFixed(2)}px;
      `;
    })}
`;

export const TextWithArrow = ({
  align,
  size,
  heading,
  children,
  sizeCorrector = 1,
  tuckUp = 1,
  icon = "arrow",
  filter,
}: {
  align: "left" | "right" | "up" | "down";
  heading: string;
  icon?: string;
  size: number;
  sizeCorrector?: number;
  tuckUp?: number;
  children: React.ReactNode;
  filter?: string;
}) => {
  return (
    <Container align={icon === "arrow" ? align : "right"} size={size} heading={heading}>
      <Text>{children}</Text>
      <Arrow size={size} align={align} sizeCorrector={sizeCorrector} tuckUp={tuckUp} filter={filter}>
        <SvgBackground
          className="svg open"
          type={icon}
          position="left center"
          width="100%"
          height="100%"
        />
      </Arrow>
    </Container>
  );
};

export default TextWithArrow;
