import styled from "styled-components";

export const HeroSlideshowContainer = styled.div<{hasBottomMargin?: boolean}>`
  ${(props) =>
    props.theme.apply("default", (breakpoint: string) => {
      return `
        ${props.hasBottomMargin ? `margin-bottom: ${props.theme.spacePx(breakpoint, 4)};`:``}
        height: 100vh;
        min-height: 200px;
        max-height: min(
          calc(0.60 * (100vw - var(--sbw, 0px) - 4vw)), 
          calc(${props.theme.spacePx(breakpoint, 1)} + ${props.theme.spacePx(
        breakpoint,
        2
      )}), 
          calc(100vh - ${props.theme.spacePx(
            breakpoint,
            5
          )} - ${props.theme.spacePx(breakpoint, 3)})
        );
        
        `;
    })}
`;