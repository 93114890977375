import styled from "styled-components";

export const HeaderTwoCol = styled.span`
  display: flex;
  justify-content: space-between;
  a {
    text-decoration: none;
  }
  ${(props) =>
    props.theme.apply("default", (breakpoint: string) => {
      return `
        margin-bottom: ${props.theme.spacePx(breakpoint, 9)}; 
      `;
    })}
`;

export const HeaderTwoColCol = styled.div<{gapSize?: number;}>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 100%;

  ${(props) =>
    props.theme.apply("default", (breakpoint: string) => {
      return `
        column-gap: ${props.theme.spacePx(breakpoint, props.gapSize ?? 9)}; 
      `;
    })}
`;
